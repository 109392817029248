import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
// import { randomNumber } from "../utils/miscFunctions";
import { PageProps } from "gatsby";
import TacoList from "../components/TacoList";
import {
  Share,
  Typography,
  Image,
  Accordion,
  Breadcrumb,
} from "@cncxt/imodium-component-library";
import styles from "../styles/product.module.scss";
import Seo from "../components/Seo";

interface ProductDetailsInterface extends PageProps {
  pageContext: {
    uuid: string;
    urlPath: string;
    title: string;
    pageType: string;
    components: string;
    footerData: string;
    meta: {
      property_key_name: string;
      property_key_value: string;
      content: string;
    }[];
    canonical: {
      ref: string;
      href: string;
    };
    hreflang: {
      rel: string;
      hreflang: string;
      href: string;
    }[];
    breadcrumbs: {
      title: string;
      link: string;
    }[];
  };
}

const ProductDetails = (data: ProductDetailsInterface): JSX.Element => {
  const dataContext = data.pageContext;
  const footerData = dataContext.footerData;
  const componentData = JSON.parse(dataContext.components);
  const productDetailObj = componentData.find(
    data => data.type === "product_detail",
  );
  const tacoListObj = componentData.find(data => data.type === "taco_list");
  const breadcrumbList =
    dataContext.breadcrumbs !== null
      ? dataContext.breadcrumbs.map(path => {
          return { label: path.title, link: path.link };
        })
      : [];
  return (
    <DefaultLayout siteFooterData={footerData}>
      <>
        <Seo
          metaArray={dataContext.meta}
          canonicalArray={dataContext.canonical}
          hreflangArray={dataContext.hreflang}
        />
        {dataContext.breadcrumbs !== null && (
          <Breadcrumb listItems={breadcrumbList} variation={"linear"} />
        )}
        <div className={styles.productDetailLayout}>
          <div className={styles.productTitleWrapper}>
            <Typography variant="h1" color="ternary">
              {productDetailObj.content.title}
            </Typography>
            <Share emailLink />
          </div>
          <div className={styles.productDetailWrapper}>
            <div className={styles.imageWrapper}>
              {productDetailObj.content.images[0] && (
                <Image url={productDetailObj.content.images[0].image.src} />
              )}
            </div>
            <div className={styles.accordionWrapper}>
              {productDetailObj.content.details &&
                productDetailObj.content.details.map((data, index) =>
                  data.label == "Buy" ? (
                    <div key={index} className={styles.accordionOuter}>
                      <Accordion
                        imageList={{
                          label: data.label,
                          items: data.content,
                        }}
                        underlineBelow={true}
                        isExpanded={true}
                      />
                    </div>
                  ) : (
                    <div key={index} className={styles.accordionOuter}>
                      <Accordion
                        textList={{
                          label: data.label,
                          description: data.description,
                        }}
                        isExpanded={true}
                      />
                    </div>
                  ),
                )}
            </div>
          </div>
        </div>
        {tacoListObj && (
          <TacoList width={tacoListObj.width} data={tacoListObj.content} />
        )}
      </>
    </DefaultLayout>
  );
};

export default ProductDetails;
